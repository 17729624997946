// Hero
function parallaxHero() {
  var scrollVar = $(window).scrollTop();
  var ticking = false;

  // Hero
  var $depth2 = $('.hero.hero--image .wrap, .hero.hero--home .wrap, .hero.hero--project .wrap');
  var $bottom = $('.bottom');

  // // Counter
  // var $counter = $('section.counter');

  // Sliders
  var $sliders = $('section.featured');

  // Two Tile
  var $tiles = $('.two-tile__image');

  // Call Out
  var $galleryItem = $('.gallery__item');

  // Everything plz
  var $all = $('.hero.hero--image .bg-img, .hero.hero--home .bg-img, .hero.hero--project .bg-img, .hero.hero--image .wrap, .hero.hero--home .wrap, .hero.hero--project .wrap');

  function parallax(){
    ticking = false;
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var $postList = $('.posts.post-list');
    var $depth1 = $('.hero.hero--image .bg-img, .hero.hero--home .bg-img, .hero.hero--project .bg-img');

    if(windowWidth >= 768){

      // Hero
      var limitedScrollVar = scrollVar * 0.5;
      var opacity = (1 - scrollVar/($(window).height() * 1.4));
      var opacity2 = (1.05 - scrollVar/($(window).height() * 0.9));

      $depth1.css({ 'transform': 'translate3d(0px, ' + (0.7 * limitedScrollVar) + 'px, 0px)' });
      $depth2.css({ 'transform': 'translate3d(0px, ' + (0.3 * limitedScrollVar) + 'px, 0px)', 'opacity': opacity2 });

      if($bottom.length > 0) {
        $bottom.css({ 'opacity': opacity });
      }

      // Surfaces and Systems
      if($sliders.length > 0) {
        $sliders.css({
          'overflow': 'hidden'
        });
        $sliders.find('.bg-img').css({
          'height': '120%',
          'top': 'auto',
          'bottom': '0'
        });

        $sliders.each(function() {
          that = $(this);
          var thisRatio = 0.2;
          var thisScoll = (scrollVar - (that.offset().top - windowHeight)) * 0.5;
          var thisScrolled = thisScoll * thisRatio;
          var thisOffsetMax = that.height();
          var thisBg = that.find('.bg-img');

          if(scrollVar >= (that.offset().top - windowHeight) && thisScrolled <= thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + (thisRatio * thisScoll * 1) + 'px, 0px)' });
          }
          else if(thisScrolled > thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + thisOffsetMax + 'px, 0px)' });
          }
          else {
            thisBg.css({ 'transform': 'translate3d(0px, 0px, 0px)' });
          }
        });
      }

      if($tiles.length > 0) {
        $tiles.each(function() {
          that = $(this);
          var thisRatio = 0.2;
          var thisScoll = (scrollVar - (that.offset().top - windowHeight)) * 0.5;
          var thisScrolled = thisScoll * thisRatio;
          var thisOffsetMax = that.height();
          var thisBg = that.find('.bg-img');

          if(scrollVar >= (that.offset().top - windowHeight) && thisScrolled <= thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + (thisRatio * thisScoll * -1) + 'px, 0px)' });
          }
          else if(thisScrolled > thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + thisOffsetMax + 'px, 0px)' });
          }
          else {
            thisBg.css({ 'transform': 'translate3d(0px, 0px, 0px)' });
          }
        });
      }
    }
    else {
      $all.css({ 'transform': 'initial', 'opacity': 1, 'top': 'auto' });
    }
   }

  function requestTick() {
    if(!ticking) {
      window.requestAnimationFrame(parallax);
    }
    ticking = true;
  }

  $(window).scroll(function(){
    scrollVar = $(window).scrollTop();
    requestTick();
  });
  requestTick();
}

// Init
function parallaxInit() {
  parallaxHero();
}