function hideSearch() {
  $(".search-feature__form").velocity({
    opacity: 0,
    scale: [0.8, 1],
  }, 300, 'easeInOutCubic', function() {
    $(".search-feature").velocity('fadeOut', 400, 'easeInCubic').removeClass('visible');
  });
}

function showSearch() {
  $(".search-feature").velocity('fadeIn', 400, 'easeInCubic', function() {
    $(".search-feature__form").velocity({
      opacity: 1,
      scale: [1, 0.8],
    }, 300, 'easeInOutCubic', function() {
      if(BrowserDetect.browser !== 'Explorer') {
        $(".search-form__search").focus();
      }
    });

    $(this).addClass('visible');
  });
}

$(function(){
  // Show that search overlay
  $('.search-feature__toggle').click(function(e){
    showSearch();
    e.preventDefault();
  });

  // Hide that search overlay
  $('body').on('click', 'search-feature__close, .search-feature.visible .search-feature__content', function(e){
    hideSearch();
    e.preventDefault();
  });

  // Stahp
  $('.search-feature form').on('click', function(e) {
    e.stopPropagation();
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27 && $('.search-feature').hasClass('visible')) {
      hideSearch();
    }
  });
});
